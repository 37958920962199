<template>
  <aside>
    <header>
      <h2>FTP Sync</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>FTP Configuration</h4>
          <p>Details to configure your FTP connection to HiThrive.</p>
        </div>
        <div class="fields">
          <div class="dayforce-auth" v-if="active === true">
            <div class="d-flex cred-row align-items-start">
              <label>FTP Host</label>
              <div>
                <pre>ftp-sync.hithrive.com</pre>
              </div>
            </div>
            <div class="d-flex cred-row align-items-start">
              <label>FTP Username</label>
              <div>
                <pre>{{slug}}</pre>
              </div>
            </div>
            <div class="d-flex cred-row align-items-start">
              <label>FTP Password</label>
              <div>
                <pre>{{integration.configuration.secret}}</pre>
              </div>
            </div>
          </div>

          <button @click.prevent="rollCreds" class="btn btn-xs btn-green">{{active ? 'Roll credentials' : 'Generate credentials'}}</button>
        </div>
      </div>

      <div class="setting-row field-map">
        <div class="title">
          <h4>Field mapping</h4>
          <p>Map your CSV fields to HiThrive fields.</p>
        </div>
        <div class="fields">
          <table class="table">
            <tr v-for="(field, index) in $v.fields.$each.$iter" :key="`field_map_${index}`">
              <td width="15">
                <a href="#" v-if="field.id !== 'email'" class="delete" @click.prevent="removeField(index)">
                  <i class="fas fa-trash"></i>
                </a>
              </td>
              <td>
                <input type="text" v-model="field.id.$model" class="form-control form-control-sm" placeholder="CSV column name...">
              </td>
              <td width="25">
                <i class="fas fa-long-arrow-right"></i>
              </td>
              <td>
                <input type="text" class="form-control form-control-sm" v-model="field.label.$model" placeholder="Enter HiThrive field name..." />
              </td>
            </tr>
          </table>
          <button class="btn btn-xs btn-dark" @click.prevent="addField"><i class="fas fa-plus"></i> Add column</button>
        </div>
      </div>

      <div class="setting-row" v-if="active">
        <div class="title">
          <h4>Statistics</h4>
          <p>Latest details about your integration.</p>
        </div>
        <div class="fields stats">
          <ul>
            <li class="status">
              <strong>Status</strong>
              <span :class="[integration.status]"><span>{{status_label}}</span></span>
            </li>
            <li>
              <strong>Last sync</strong>
              <span>{{last_sync_time}}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Sync Logs</h4>
          <p>View synchronization activity for the last 72 hours.</p>
        </div>
        <div class="fields">
          <ul v-if="logs.length > 0">
            <li v-for="(log, index) in logs" :key="`log_${index}`">
              <a href="#" @click.prevent="openLog(log)">{{$formatDate(log.created_at, $DateTime.DATETIME_SHORT)}}</a>
            </li>
          </ul>
          <span v-else>No logs available.</span>
        </div>
      </div>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :class="{'loading': loading}" :disabled="!canSave" @click="saveFields">Save fields</button>
    </footer>
  </aside>
</template>

<script>
import {required, minLength} from 'vuelidate/lib/validators'

export default {
  computed: {
    team() {
      return this.$store.state.team;
    },
    integration() {
      return this.$store.state.integrations.find(i => i.integration_type === 'FTP_SYNC') || {};
    },
    status_label() {
      switch(this.integration.status) {
        case "INACTIVE":
          return 'Inactive';
        case "ACTIVE":
          return 'Active';
      }
    },
    active() {
      return this.integration.status === 'ACTIVE' ? true : false;
    },
    last_sync_time() {
      if(!this.integration.metadata.last_sync) return 'Pending';

      return this.$formatDate(this.integration.metadata.last_sync, this.$DateTime.DATETIME_SHORT);
    },
    canSave() {
      return !this.$v.$invalid;
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      slug: '',
      fields: [],
      logs: []
    }
  },
  validations: {
    fields: {
      minLength: minLength(1),
      needsEmailMap: (rows) => rows.some((row) => row.label.toLowerCase() === 'email'),
      noDupes: (rows) => rows.every((row, index) => rows.findIndex((p, pIndex) => index !== pIndex && p.label === row.label) === -1),
      $each: {
        id: {
          required
        },
        label: {
          required
        }
      }
    }
  },
  mounted() {
    this.populateLogs()

    this.slug = this.team.slug;

    this.fields = Object.entries(this.integration.configuration.field_map).map(([k, v]) => ({
      id: k,
      label: v
    }))
  },
  methods: {
    async rollCreds() {
      const resp = await this.$api.Integrations.FTP.roll_credentials();
      this.slug = resp.slug;
      await this.$store.dispatch('populateIntegrations')
    },
    async saveFields() {
      this.$store.commit('loading', true)

      const resp = await this.$api.Integrations.FTP.set_field_map(this.fields)

      this.$store.commit('loading', false)
    },
    removeField(index) {
      this.$v.fields.$model.splice(index, 1);
    },
    addField() {
      this.$v.fields.$model.push({
        id: '',
        label: ''
      })
    },
    openLog(log) {
      this.$root.$emit(
        'openModal',
        'Integrations/LogViewer',
        log,
        () => {}
      )
    },
    async populateLogs() {
      if(!_.isEmpty(this.integration)) {
        const resp = await this.$api.Integrations.get_logs(this.integration.id)
        this.logs = resp;
      }
    },
    close() {
      this.$emit('done')
    }
  }
}
</script>

<style lang="scss" scoped>
.dayforce-auth {
  background: #fff;
  box-shadow: rgba(0,0,0,0.05) 0 5px 10px;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 5px 0;
  max-width: 380px;
  min-height: 50px;

  a {
    font-size: 12px;
    font-weight: 700;
  }

  .cred-row {
    padding: 10px 15px;
    border-bottom: 1px solid $light;
    i {
      margin: 0 5px 0 0;
      color: $muted-text;
    }

    &:last-child {
      border: none;
    }
  }

  pre {
    margin: 0;
    font-size: 12px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  label {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 0 10px 0 0;
    text-align: right;
    width: 105px;
    color: $muted-text;
  }
}

.field-map {
  .table {
    .delete {
      font-size: 12px;
      color: $red;
    }
  }
}

.stats {
  > ul {
    display: block;
    margin: 0;
    padding: 0;

    > li {
      display: flex;
      border-bottom: 1px solid $light;
      padding: 5px 0;

      > strong {
        flex: 0 0 35%;
        display: block;
      }

      > span {
        flex: 1 0 1;
        display: block;
      }

      &.status {
        > span {
          > span {
            border-radius: 3px;
            background: $light;
            padding: 3px 5px;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 1em;
          }
          &.ACTIVE {
            > span {
              background: $green;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>